// List of EU countries to exclude from the dropdown when on triomarkets.com
export const excludedEuCountries = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czechia/Czech Republic
  "DK", // Denmark
  "EE", // Estonia
  "EU", // European Union
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
  "AP", // Asia/Pacific Region
];

// Helper function to check if a country should be excluded based on domain and user's IP
export const shouldExcludeCountry = (isoCode, isGlobalDomain, userIpMatchesEuCountry) => {
  console.log(`[COUNTRY FILTER] Checking country: ${isoCode}, Global domain: ${isGlobalDomain}, User IP from EU: ${userIpMatchesEuCountry}`);
  
  if (!isGlobalDomain) {
    // Allow all countries on .eu domain
    console.log(`[COUNTRY FILTER] Not on .com domain - allowing all countries`);
    return false;
  }
  
  // On .com domain, exclude EU countries for all users including EU users
  const shouldExclude = excludedEuCountries.includes(isoCode);
  console.log(`[COUNTRY FILTER] Country ${isoCode} ${shouldExclude ? 'EXCLUDED' : 'INCLUDED'}`);
  return shouldExclude;
}; 