<template>
  <div v-if="siteStatus" :class="['wrapper blurred', backgroundImage]">
    <router-view class="animated fadeIn" />
    <Cookies v-if="!cookiesEnabled" />
    <Regulation
      v-if="isGlobal && !regulationAccepted && isEurope == true"
      @acceptRegulation="acceptRegulation"
    />
  </div>
  <Maintenance v-if="!siteStatus" />
</template>
<script>
import { getters, mutations } from "../store";
import Cookies from "../components/Cookies.vue";
import Regulation from "../components/Regulation";
import Maintenance from "../components/Maintenance";
import { checkCountry } from "../helpers/countries";
import { shouldExcludeCountry, excludedEuCountries } from "../helpers/excludedEuCountries";

export default {
  data() {
    return {
      siteStatus: true,
      isEurope: false,
      isProduction: process.env.NODE_ENV !== "development",
    };
  },
  beforeMount() {
    if (this.isProduction) {
      this.checkSiteStatus();
    }
  },
  mounted() {
    if (this.siteStatus) {
      this.getCountries();
      this.changeLanguage();
    }
  },
  watch: {
    $route() {
      this.changeLanguage();
    },
  },
  components: {
    Regulation,
    Maintenance,
    Cookies,
  },
  computed: {
    license() {
      return getters.license();
    },
    regulationAccepted() {
      return getters.regulationAccepted();
    },
    isGlobal() {
      return this.license === "global";
    },
    backgroundImage() {
      return this.isGlobal ? "global" : "";
    },
    countries() {
      return getters.countries();
    },
    host() {
      return window.location.host;
    },
    cookiesEnabled() {
      return getters.cookiesEnabled();
    },
    selectedLanguage() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    changeLanguage() {
      document.title = `TrioMarkets | ${this.$route.name}`;
      this.$root.$i18n.locale = this.$route.params.lang;
      mutations.changeLanguage(this.$root.$i18n.locale);
      if (this.$root.$i18n.locale === "arb") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    },
    checkSiteStatus() {
      this.$axios({
        method: "GET",
        url: `/hub/maintenance/checkSite/${this.host}`,
      })
        .then(({ data }) => {
          if (data.success) {
            this.siteStatus = data.status;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getCountries() {
      console.log(`[COUNTRIES] Getting countries list, isGlobal: ${this.isGlobal}`);
      this.$axios({
        method: "GET",
        url: `/crm/countries`,
      })
        .then(({ data }) => {
          if (data.success) {
            console.log(`[COUNTRIES] Received ${data.countriesList.length} countries from API`);
            let countries = data.countriesList.filter(({ iso_alpha2_code }) => {
              if (this.isGlobal) {
                if (iso_alpha2_code === "GB" || iso_alpha2_code === "CH") {
                  let changeISO = iso_alpha2_code;
                  let findCountry = data.countriesList.find(
                    ({ iso_alpha2_code }) => {
                      return changeISO === iso_alpha2_code;
                    }
                  );
                  if (findCountry) {
                    findCountry.currency = "USD";
                  }
                }
                // Basic filtering for certain country codes
                const basicFilter = (
                  iso_alpha2_code !== "KSV" &&
                  iso_alpha2_code !== "TP" &&
                  iso_alpha2_code !== "RO"
                );
                
                // Don't exclude EU countries if user's IP is from EU
                return basicFilter && !shouldExcludeCountry(iso_alpha2_code, this.isGlobal, this.isEurope);
              }
              return iso_alpha2_code !== "KSV" && iso_alpha2_code !== "TP";
            });
            console.log(`[COUNTRIES] After filtering, ${countries.length} countries remain`);
            mutations.setCountries(countries);
            this.getCountry();
          }
        })
        .catch((err) => {
          console.log(`[COUNTRIES ERROR] ${err.message}`);
        });
    },
    getCountry() {
      console.log(`[IP DETECTION] Starting IP detection process`);
      this.$axios.get("https://api.ipify.org?format=json").then(({ data }) => {
        console.log(`[IP DETECTION] User IP detected: ${data.ip}`);
        this.$axios.get(`ip/findIP/${data.ip}`).then(({ data }) => {
          let clientCountry = data.country.iso_code.toUpperCase();
          console.log(`[IP DETECTION] User country detected: ${clientCountry}`);
          
          let { country_id, tel_country_code, currency } = this.countries.find(
            ({ iso_alpha2_code }) => {
              return iso_alpha2_code === clientCountry;
            }
          ) || { country_id: 0, tel_country_code: "", currency: "" };
          
          console.log(`[IP DETECTION] Found country data: ID=${country_id}, Phone prefix=${tel_country_code}, Currency=${currency}`);
          
          let countryFound = checkCountry(clientCountry, "en");
          if (countryFound) {
            console.log(`[LANGUAGE] Setting language to: ${countryFound} based on user's country`);
            this.$router.replace({
              name: this.$route.name,
              params: { lang: countryFound },
              query: this.$route.query,
            });
          }
          
          mutations.setCountry({
            country_id: country_id.toString(),
            phoneExt: tel_country_code,
            currency: currency,
          });
          
          mutations.setUser({
            ...getters.user(),
            ip: this.isProduction ? data.ip : "81.4.163.54",
          });
          
          this.checkEurope(clientCountry);
        }).catch(err => {
          console.log(`[IP DETECTION ERROR] Error finding IP: ${err.message}`);
        });
      }).catch(err => {
        console.log(`[IP DETECTION ERROR] Error detecting IP: ${err.message}`);
      });
    },
    checkEurope(ipCode) {
      console.log(`[EU CHECK] Checking if user country ${ipCode} is in EU list`);
      // Use our excludedEuCountries array to check if user is from EU
      this.isEurope = excludedEuCountries.includes(ipCode);
      console.log(`[EU CHECK] User is ${this.isEurope ? 'from EU' : 'not from EU'}`);
    },
    acceptRegulation() {
      console.log(`[REGULATION] User accepted regulation`);
      mutations.setRegulation();
    },
  },
};
</script>
